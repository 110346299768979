import { useHead, useJsonld } from '#imports'
import type { ISeo } from '~/api/types/response'

export default function useSeo(seo: ISeo) {
  const title = import.meta.env.VITE_WEBSITE_TITLE
  const description = seo?.meta_description
  const image = seo?.og_image
  useSeoMeta({
    title: title,
    ogTitle: title,
    twitterTitle: title,
    description: description,
    ogDescription: description,
    twitterDescription: description,
    ogImage: image,
    twitterImage: image,
    keywords: (seo?.meta_keywords || []).join(',')
  })

  useHead({
    link: [
      {
        rel: 'canonical',
        href: import.meta.env.VITE_APP_BASE_URL
      }
    ]
  })

  useJsonld({
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Home',
            item: import.meta.env.VITE_APP_BASE_URL
          }
        ]
      }
    ]
  })
}
